@import 'apps/admin/src/styles/variables';

.modal-dialog {
  margin: 120px auto;
}

.modal-content {
  max-width: 900px;
  border: 1px solid #cbcbcd;
  border-radius: 8px;
  padding: 40px;
  font-family: $font-family;
}

.modal-header {
  border-radius: 0;
  padding: 0;
  padding-bottom: 16px;
  position: relative;
  .close {
    margin: 0;
    padding: 0;
    position: absolute;
    top: -8px;
    right: -8px;
    color: inherit;
    opacity: 1;
    svg {
      width: 18px;
      height: 18px;
      * {
        fill: #4b4b4e;
      }
    }
    path {
      fill: #4b4b4e;
    }
  }
  .border-bottom {
    border-bottom: none;
  }
}

.ot-approval-modal {
  .modal-dialog {
    max-width: 680px;
    width: 680px;
  }
}

.ot-modal-lg {
  .modal-dialog {
    max-width: 850px;
    width: auto;
  }
}

.modal-title {
  color: #19191a;
  font-weight: 600;
  font-size: 24px;
  line-height: 34px;
}

.modal-footer {
  border-top: none;
  .btn-secondary {
    margin-right: 16px;
  }
}

.modal-body {
  label {
    margin-bottom: 0;

    ot-label {
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      color: #323234;
    }
  }

  ot-form-container {
    input {
      border: 1px solid #cbcbcd;
      padding: 8px 30px 8px 16px;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      color: #19191a;

      &::placeholder {
        color: #b1b1b4 !important;
      }
    }

    .arrow-icon {
      width: 20px !important;
    }

    .dropdown-menu {
      .dropdown-item {
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: #19191a;
      }
    }
  }

  textarea {
    max-height: 160px;
    padding: 8px 19px 8px 16px;
    resize: none;
    border: 1px solid #cbcbcd;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #19191a;

    &:focus {
      box-shadow: none;
      border: 1px solid #cbcbcd;
    }

    &::placeholder {
      color: #b1b1b4;
    }
  }

  ot-form-error {
    color: #fd6854 !important;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
  }

  .form-group {
    margin-bottom: 24px;
  }

  &-text {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
  }

  ot-timepicker {
    input {
      padding: 5px;
    }
  }
}

.merge-modal .modal-dialog {
  max-width: 80vw;
  width: 100%;
  @media screen and (max-width: 1024px) {
    max-width: 95vw;
  }
}

.modal-body,
.modal-footer {
  padding: 0px;
  > * {
    margin: 0;
  }
}

.modal {
  &-footer {
    border-radius: 0 !important;
    padding-top: 8px;
  }

  &-header {
    border-radius: 0 !important;
    border-bottom: none;
  }
}

@media (max-width: 767px) {
  .modal {
    &-dialog {
      margin: 40px 15px 0;
    }

    &-content {
      padding: 24px;
    }

    &-header {
      padding-bottom: 22px;

      .close {
        top: 0;
        right: 0;
      }
    }

    &-title {
      padding-right: 20px;
    }
  }
}
